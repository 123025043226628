import React from 'react';

//tools
import classnames from "classnames";
import {InView} from "react-intersection-observer";

const OurValues = ({title,text,txtColor}) => {
    return (
        <InView>
            {({ inView, ref }) => (
            <article
                id="our-values"
                className="boxed"
                style={{color:txtColor}}
                ref={ref}
            >
                <div className="row center-xs start-sm">
                            <div className="col col-xs-12 col-sm-2">
                                <h2 className={classnames(
                                    "section-title section-title__md",{
                                        "animate":inView
                                    }
                                )}>
                                    {title}
                                </h2>
                            </div>
                            <div className="col col-xs-12 col-sm-6">
                                <p dangerouslySetInnerHTML={{__html:text}}/>
                            </div>
                        </div>
            </article>
            )}
        </InView>
    );
};

export default OurValues;