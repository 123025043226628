import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";

//actions
import { toggleOverlay } from "../../actions/pageActions";
import {
    setPageData,
    setPageOptions,
    setPageOptionsNews,
    setPageOptionsMembers,
    setPageOptionsDocs
} from "../../actions/pageActions";

// components
import Header from '../Header';

//config
import { config } from "../../config";

class Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: []
        };
        this.handleOverlay = this.handleOverlay.bind(this);
    }
    handleOverlay() {
        this.props.showOverlay(false);
    }


    componentDidMount(props) {
        const {
            pageIndex,
        } = this.props;
        axios
            .get(
                config.domainRelPath+'/wp-json/acf/v3/pages/?per_page=30'
            )
            .then(res => {
                this.props.dispatchPageData(res.data[pageIndex].acf);
                this.handleOverlay();
            })
            .catch(error => console.log(error));

        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-vistula')
            .then( res => { this.props.dispatchPageDataOptions( res.data.acf ) } )
            .catch(error => console.log(error));

        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-vistula-news')
            .then( res => { this.props.dispatchPageDataNews( res.data.acf ) } )
            .catch(error => console.log(error));

        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-vistula-docs')
            .then( res => { this.props.dispatchPageDataDocs( res.data.acf.dokumenty ) } )
            .catch(error => console.log(error));

        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-vistula-team')
            .then( res => { this.props.dispatchPageDataMembers( res.data.acf ) } )
            .catch(error => console.log(error));

        window.scrollTo(0,0)
    }

    render() {
        return (
            <Fragment>
                <Header/>
                {this.props.children}
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    showOverlay: value => dispatch(toggleOverlay(value)),
    dispatchPageData: data => dispatch( setPageData(data) ),
    dispatchPageDataOptions: data => dispatch( setPageOptions(data) ),
    dispatchPageDataDocs: data => dispatch( setPageOptionsDocs(data) ),
    dispatchPageDataNews: data => dispatch( setPageOptionsNews(data) ),
    dispatchPageDataMembers: data => dispatch( setPageOptionsMembers(data) )
});

export default connect(null,mapDispatchToProps)(Page);

