import React from 'react';

//tools
import classnames from "classnames";
import {InView} from "react-intersection-observer";
import Arrow from "../../../svg/Arrow";
import MediaQuery from "react-responsive";

const OurPartners = ({title,partners,txtColor,bgColor}) => {
    return (
        <InView>
            {({ inView, ref }) => (
            <article
                id="our-partners"
                className="boxed"
                style={{color:txtColor}}
                ref={ref}
            >

                {/*<MediaQuery minWidth={769}>
                    <div
                        className={classnames(
                            "row incoming",{
                                "bounce":inView
                            }
                        )}
                        style={{color:bgColor}}
                        aria-hidden={true}
                    >
                        <div className="col col-xs-12 col-sm-1"/>
                        <div className="col col-xs-12 col-sm-11">
                            <p><Arrow mainColor={bgColor}/></p>
                            <h2 className="section-title section-title__md">
                                {title}
                            </h2>
                        </div>
                    </div>
                </MediaQuery>*/}
                <div className="row center-xs start-sm">
                    <div
                        className="col col-xs-12 col-sm-1"
                        style={{
                            backgroundColor: bgColor
                        }}
                    />
                    <div className="col col-xs-12 col-sm-11">
                        <div className="row">
                            <div
                                className="col col-xs-12 col-sm-6"
                                style={{
                                    backgroundColor: bgColor
                                }}
                            >
                                <div className={classnames(
                                    "title-line ",{
                                        "stretch":inView
                                    }
                                )}/>
                                <h2
                                    style={{ borderTopColor:txtColor }}
                                    className="section-title section-title__xl">
                                    {title}
                                </h2>
                            </div>
                            <div className="col col-xs-12 col-sm-6"/>
                        </div>
                        {
                            partners &&
                            partners.map((item,index)=>
                            <div
                                className="row partners-item"
                                id={item.id}
                                key={index}>
                                <div
                                    className="col col-xs-12 col-sm-6 partners-item__text"
                                    style={{
                                        backgroundColor: bgColor
                                    }}
                                >
                                    <h3 style={{borderBottomColor: txtColor}}>
                                        {item.op_list_name}
                                    </h3>
                                    <span dangerouslySetInnerHTML={{__html:item.op_list_text}}/>
                                </div>
                                <div className="col col-xs-12 col-sm-6 partners-item__img">
                                    <h3 style={{borderBottomColor: bgColor}}>&nbsp;</h3>
                                    <img
                                        src={item.op_list_icon}
                                        width={225}
                                        height={194}
                                        alt={`Icon: ${item.op_list_name}`} />
                                </div>
                            </div>
                            )

                        }


                    </div>

                </div>

            </article>
            )}
        </InView>
    );
};

export default OurPartners;