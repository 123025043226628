import React,{useState} from 'react';

//tools
import classnames from "classnames";
import TextTruncate from 'react-text-truncate';
import {InView} from "react-intersection-observer";
import MediaQuery from 'react-responsive';

const WhatWeDo = (
    {
        title,
        offer,
        bgImage,
        txtColor,
        locales
    }) => {
    const [offerItem,setOfferItem] = useState(null);
    return (
        <InView>
            {({ inView, ref }) => (
            <article
                id="what-we-do"
                className="boxed"
                style={{ color:txtColor }}
                ref={ref}
            >
                <div
                    className="row"
                    style={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                    }}
                >
                    <div className="col col-xs-12 col-sm-1" />
                    <div
                        className="col col-xs-12 col-sm-11 box"
                        style={{color:txtColor}}

                    >
                        <MediaQuery minWidth={769}>
                        <div className="row">
                            <div
                                className={classnames(
                                    "col col-xs-11 line",{
                                        "stretch":inView
                                    }
                                )}
                                style={{ borderTopColor: txtColor }}
                            />
                        </div>
                        </MediaQuery>
                        <div className="row center-xs start-sm">
                            <div className="col col-xs-12 "/>
                            <div className="col col-xs-12 col-sm-3 title">
                                <h2 className={classnames(
                                    "section-title section-title__xl",{
                                        "animate":inView
                                    }
                                )}>
                                    <span>{title}</span>
                                </h2>
                            </div>
                            <div className="col col-xs-12 col-sm-8 offer">
                            {
                            offer &&
                            offer.map((item,index)=>
                                <div
                                    className="row offer-item"
                                    id={item.id}
                                    key={index}>
                                    <div className="col col-xs-12 col-sm-4">
                                        <span className="offer-item__title">{item.wwd_offer_name}</span>
                                    </div>
                                    <div className="col col-xs-12 col-sm-8">
                                        <p className={classnames(
                                            "description",{
                                                "expanded" : index === offerItem
                                            }
                                        )}>
                                            <TextTruncate
                                                line={(index===offerItem) ? 99 : 99}
                                                element="span"
                                                truncateText="…"
                                                text={item.wwd_offer_text}
                                                textTruncateChild={
                                                    <span
                                                        onClick={()=>setOfferItem(index)}
                                                        role="button"
                                                    >
                                                        {locales.more}
                                                    </span>
                                                }
                                            />

                                        </p>


                                    </div>
                                    <div
                                        className={classnames(
                                            "col",
                                            "col-xs-8",
                                            "col-xs-offset-2",
                                            "col-sm-3",
                                            "line line-offer"
                                        )}
                                    />
                                </div>
                            )
                            }
                            </div>
                        </div>
                    </div>
                </div>

            </article>
            )}
        </InView>
    );
};

export default WhatWeDo;