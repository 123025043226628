import React from 'react';

const LogoStrip = () => {
    return (
        <svg width="140" height="1015"
             viewBox="0 0 140 1015" fill="none"
             xmlns="http://www.w3.org/2000/svg"
             aria-labelledby="vistula-gp-logo"
        >
            <title id="vistula-gp-logo">Vistula GP Logo</title>
            <path d="M1 0H139V503.852L1 548V0Z" fill="#FCFEFF" stroke="#005191"/>
            <g clipPath="url(#clip0)">
                <path d="M83.5248 105.778L83.5248 115.763L89.0955 113.904L89.0955 103.743L83.5248 105.778Z" fill="#004F90"/>
                <path d="M72.1404 131.914L53.9969 125.605L68.9196 120.633L68.9196 111.13L39.3511 121.963L39.3511 129.675L89.0955 147.827L89.0955 137.666L80.304 134.647L80.304 123.014L72.1404 125.748L72.1404 131.914Z" fill="#004F90"/>
                <path d="M89.0955 345.968L39.4186 345.968L39.4186 336.227L89.0887 336.227L89.0887 345.968L89.0955 345.968Z" fill="#004F90"/>
                <path d="M70.6009 366.087L39.4186 355.702L39.4186 365.863L55.3339 370.794L70.6009 366.087Z" fill="#004F90"/>
                <path d="M53.3284 381.599L39.4186 385.907L39.4186 396L89.0955 379.531L89.0955 372.239L86.4891 371.377L53.3284 381.599Z" fill="#004F90"/>
                <path d="M85.6045 297.041C88.2109 300.453 89.5141 304.842 89.5141 310.214C89.5141 318.015 87.3264 324.092 82.9576 328.434L76.678 322.125C79.4667 319.324 80.8644 315.301 80.8644 310.072C80.8644 304.184 78.8184 301.24 74.7265 301.24C72.9102 301.24 71.5192 301.756 70.5401 302.78C69.7028 303.716 69.1221 305.188 68.798 307.196L67.9607 313.226C67.3598 317.479 65.9823 320.701 63.8418 322.899C61.4717 325.279 58.2171 326.473 54.0712 326.473C49.5606 326.473 45.9346 324.933 43.1864 321.847C40.3977 318.673 39 314.42 39 309.095C39 302.366 40.9312 296.994 44.7935 292.978L50.8638 299.144C48.5883 301.525 47.4472 304.937 47.4472 309.373C47.4472 311.849 48.0751 313.789 49.3311 315.193C50.4452 316.455 51.9104 317.085 53.7268 317.085C55.1718 317.085 56.3535 316.597 57.2853 315.614C58.3116 314.494 58.9599 312.948 59.2367 310.987L60.0672 305.106C60.675 300.901 61.9511 297.726 63.9026 295.576C66.3199 293.005 69.8311 291.723 74.4362 291.723C79.1426 291.717 82.8631 293.494 85.6045 297.041Z" fill="#004F90"/>
                <path d="M48.0684 259.192L89.0887 259.192L89.0887 268.932L48.0684 268.932L48.0684 281.969L39.4186 281.969L39.4186 246.155L48.0684 246.155L48.0684 259.192Z" fill="#004F90"/>
                <path d="M72.0661 199.69C77.2316 199.69 81.4586 201.468 84.7605 205.015C87.9206 208.474 89.5074 212.816 89.5074 218.052C89.5074 223.289 87.9273 227.63 84.7605 231.089C81.4586 234.644 77.2249 236.414 72.0661 236.414L39.4186 236.414L39.4186 226.674L71.7217 226.674C74.5577 226.674 76.7927 225.9 78.4201 224.36C80.0474 222.821 80.8644 220.718 80.8644 218.052C80.8644 215.386 80.0541 213.284 78.4201 211.744C76.7927 210.157 74.5577 209.363 71.7217 209.363L39.4186 209.363L39.4186 199.69L72.0661 199.69Z" fill="#004F90"/>
                <path d="M89.0955 189.943L39.4186 189.943L39.4186 180.202L80.439 180.202L80.439 157.567L89.0887 157.567L89.0887 189.943L89.0955 189.943Z" fill="#004F90"/>
                <path d="M100.487 275.919C100.487 277.147 100.865 278.089 101.628 278.734C102.391 279.385 103.444 279.711 104.795 279.711C105.436 279.711 106.024 279.636 106.55 279.48C107.084 279.324 107.536 279.093 107.914 278.788C108.292 278.483 108.589 278.103 108.799 277.648C109.008 277.194 109.116 276.665 109.116 276.054C109.116 275.675 109.103 275.349 109.076 275.071C109.049 274.793 109.008 274.576 108.961 274.413L104.693 274.413L104.693 272.242L110.406 272.242C110.521 272.541 110.649 273.05 110.784 273.755C110.919 274.46 110.986 275.288 110.986 276.217C110.986 277.079 110.851 277.866 110.575 278.578C110.298 279.29 109.899 279.894 109.373 280.396C108.846 280.898 108.198 281.284 107.435 281.556C106.672 281.834 105.787 281.969 104.795 281.969C103.802 281.969 102.917 281.82 102.154 281.515C101.391 281.21 100.743 280.796 100.21 280.267C99.6764 279.738 99.278 279.12 99.0011 278.408C98.7243 277.696 98.5892 276.936 98.5892 276.136C98.5892 275.586 98.623 275.091 98.704 274.664C98.7783 274.237 98.8593 273.864 98.9539 273.551C99.0484 273.239 99.1429 272.989 99.2442 272.785C99.3455 272.582 99.4198 272.446 99.467 272.364L101.236 273.016C101.034 273.362 100.858 273.789 100.716 274.298C100.561 274.82 100.487 275.356 100.487 275.919Z" fill="#76B828"/>
                <path d="M98.7378 260.501C98.7378 258.778 99.0552 257.462 99.6831 256.546C100.311 255.631 101.27 255.176 102.566 255.176C104.18 255.176 105.267 255.977 105.841 257.571C106.105 257.353 106.422 257.102 106.8 256.831C107.178 256.56 107.583 256.275 108.022 255.99C108.461 255.705 108.914 255.427 109.379 255.163C109.845 254.898 110.298 254.661 110.743 254.457L110.743 256.885C110.332 257.102 109.92 257.34 109.501 257.591C109.082 257.842 108.677 258.1 108.286 258.357C107.894 258.615 107.523 258.873 107.171 259.124C106.82 259.375 106.51 259.612 106.24 259.829C106.253 259.992 106.26 260.128 106.26 260.243C106.26 260.358 106.26 260.467 106.26 260.569L106.26 261.62L110.75 261.62L110.75 263.791L99.0281 263.791C98.9134 263.262 98.8391 262.699 98.7986 262.102C98.758 261.505 98.7378 260.969 98.7378 260.501ZM100.608 260.345C100.608 260.806 100.628 261.227 100.662 261.62L104.504 261.62L104.504 260.67C104.504 260.141 104.477 259.68 104.416 259.273C104.356 258.866 104.254 258.534 104.106 258.262C103.957 257.991 103.755 257.788 103.505 257.652C103.255 257.516 102.931 257.448 102.546 257.448C102.181 257.448 101.871 257.516 101.621 257.652C101.371 257.788 101.169 257.991 101.02 258.249C100.872 258.507 100.763 258.812 100.703 259.171C100.642 259.531 100.608 259.917 100.608 260.345Z" fill="#76B828"/>
                <path d="M110.743 246.826L98.8593 246.826L98.8593 239.161L100.696 239.161L100.696 244.656L103.626 244.656L103.626 239.765L105.429 239.765L105.429 244.656L108.907 244.656L108.907 238.747L110.743 238.747L110.743 246.826Z" fill="#76B828"/>
                <path d="M110.743 230.784L98.8593 230.784L98.8593 223.119L100.696 223.119L100.696 228.613L103.626 228.613L103.626 223.723L105.429 223.723L105.429 228.613L108.907 228.613L108.907 222.705L110.743 222.705L110.743 230.784Z" fill="#76B828"/>
                <path d="M110.743 206.704C110.095 207.097 109.393 207.532 108.643 208.013C107.894 208.495 107.138 208.997 106.381 209.512C105.625 210.028 104.882 210.557 104.167 211.086C103.451 211.622 102.809 212.131 102.235 212.612L110.743 212.612L110.743 214.749L98.8593 214.749L98.8593 212.972C99.3387 212.51 99.9059 212.015 100.568 211.493C101.223 210.964 101.905 210.435 102.614 209.899C103.323 209.363 104.032 208.848 104.747 208.346C105.463 207.844 106.125 207.403 106.746 207.009L98.8593 207.009L98.8593 204.859L110.743 204.859L110.743 206.704Z" fill="#76B828"/>
                <path d="M98.7378 182.909C98.7378 181.234 99.0551 179.945 99.6899 179.043C100.325 178.14 101.27 177.693 102.526 177.693C103.215 177.693 103.795 177.815 104.281 178.066C104.768 178.317 105.159 178.669 105.463 179.131C105.767 179.599 105.983 180.162 106.125 180.827C106.26 181.491 106.327 182.244 106.327 183.085L106.327 184.415L110.737 184.415L110.737 186.083L99.0281 186.083C98.9134 185.615 98.8391 185.086 98.7986 184.51C98.758 183.926 98.7378 183.397 98.7378 182.909ZM100.196 182.773C100.196 183.486 100.217 184.028 100.25 184.408L104.916 184.408L104.916 183.153C104.916 182.577 104.882 182.061 104.801 181.6C104.727 181.139 104.599 180.752 104.423 180.44C104.248 180.128 104.005 179.877 103.694 179.707C103.383 179.538 102.992 179.45 102.512 179.45C102.053 179.45 101.675 179.538 101.378 179.714C101.081 179.89 100.845 180.135 100.669 180.44C100.493 180.745 100.365 181.098 100.298 181.498C100.23 181.898 100.196 182.326 100.196 182.773Z" fill="#76B828"/>
                <path d="M104.795 170.15C103.775 170.15 102.877 170.001 102.1 169.695C101.324 169.39 100.676 168.976 100.156 168.454C99.6358 167.932 99.2442 167.321 98.9809 166.63C98.7175 165.938 98.5892 165.198 98.5892 164.418C98.5892 163.652 98.7175 162.919 98.9809 162.241C99.2442 161.556 99.6358 160.959 100.156 160.443C100.676 159.928 101.324 159.514 102.1 159.209C102.877 158.904 103.775 158.754 104.795 158.754C105.814 158.754 106.712 158.904 107.489 159.209C108.265 159.514 108.914 159.921 109.433 160.443C109.953 160.959 110.345 161.563 110.608 162.241C110.872 162.926 111 163.652 111 164.418C111 165.198 110.872 165.938 110.608 166.63C110.345 167.328 109.953 167.932 109.433 168.454C108.914 168.976 108.265 169.39 107.489 169.695C106.706 169.994 105.807 170.15 104.795 170.15ZM104.795 168.393C105.517 168.393 106.172 168.305 106.76 168.115C107.347 167.932 107.847 167.667 108.259 167.328C108.67 166.989 108.988 166.575 109.211 166.087C109.433 165.598 109.548 165.049 109.548 164.445C109.548 163.842 109.433 163.292 109.211 162.811C108.988 162.329 108.67 161.915 108.259 161.576C107.847 161.237 107.347 160.979 106.76 160.789C106.172 160.606 105.517 160.511 104.795 160.511C104.072 160.511 103.417 160.606 102.83 160.789C102.242 160.972 101.743 161.237 101.331 161.576C100.919 161.915 100.601 162.322 100.379 162.811C100.156 163.292 100.041 163.842 100.041 164.445C100.041 165.049 100.156 165.605 100.379 166.087C100.601 166.575 100.919 166.989 101.331 167.328C101.743 167.667 102.242 167.925 102.83 168.115C103.417 168.298 104.072 168.393 104.795 168.393Z" fill="#76B828"/>
                <path d="M102.681 144.069C104.214 144.551 105.632 145.039 106.935 145.534C108.238 146.029 109.508 146.524 110.743 147.013L110.743 148.681C108.995 149.292 107.151 149.828 105.207 150.289C103.262 150.757 101.148 151.191 98.8593 151.591L98.8593 149.835C99.7506 149.672 100.642 149.509 101.533 149.333C102.425 149.156 103.289 148.98 104.133 148.797C104.97 148.614 105.774 148.43 106.543 148.247C107.306 148.064 108.015 147.874 108.67 147.678C107.469 147.216 106.152 146.728 104.72 146.212C103.289 145.697 101.803 145.215 100.271 144.768L100.271 143.323C101.803 142.875 103.289 142.387 104.72 141.858C106.152 141.329 107.475 140.833 108.67 140.379C108.029 140.196 107.327 140.013 106.564 139.829C105.801 139.646 104.997 139.463 104.153 139.287C103.309 139.11 102.445 138.934 101.547 138.758C100.649 138.588 99.7574 138.419 98.8661 138.256L98.8661 136.553C101.155 136.967 103.269 137.408 105.213 137.869C107.158 138.337 109.001 138.866 110.75 139.463L110.75 141.132C109.514 141.641 108.245 142.129 106.942 142.611C105.632 143.099 104.214 143.587 102.681 144.069Z" fill="#76B828"/>
                <path d="M110.743 128.651L98.8593 128.651L98.8593 121.366L100.284 121.366L100.284 126.982L103.816 126.982L103.816 121.983L105.207 121.983L105.207 126.982L109.325 126.982L109.325 120.939L110.75 120.939L110.75 128.651L110.743 128.651Z" fill="#76B828"/>
                <path d="M105.774 107.033C106.003 106.85 106.3 106.619 106.672 106.335C107.043 106.056 107.455 105.765 107.907 105.466C108.36 105.168 108.833 104.869 109.325 104.578C109.818 104.286 110.291 104.035 110.75 103.825L110.75 105.649C110.318 105.88 109.879 106.131 109.427 106.396C108.981 106.667 108.556 106.938 108.151 107.216C107.745 107.494 107.367 107.766 107.009 108.037C106.652 108.308 106.348 108.553 106.084 108.77C106.098 108.919 106.105 109.068 106.105 109.224C106.105 109.38 106.105 109.536 106.105 109.699L106.105 111.198L110.75 111.198L110.75 112.867L99.0281 112.867C98.9134 112.399 98.8391 111.87 98.7986 111.293C98.758 110.716 98.7378 110.181 98.7378 109.699C98.7378 108.024 99.0552 106.742 99.6831 105.867C100.311 104.991 101.25 104.551 102.499 104.551C103.289 104.551 103.964 104.761 104.525 105.181C105.078 105.595 105.497 106.212 105.774 107.033ZM100.196 109.563C100.196 110.276 100.217 110.818 100.25 111.198L104.727 111.198L104.727 110.011C104.727 109.434 104.7 108.919 104.639 108.458C104.585 107.996 104.471 107.61 104.315 107.298C104.16 106.986 103.93 106.735 103.64 106.565C103.35 106.396 102.965 106.307 102.485 106.307C102.04 106.307 101.668 106.396 101.371 106.565C101.074 106.735 100.838 106.972 100.669 107.264C100.5 107.556 100.379 107.901 100.311 108.295C100.244 108.688 100.196 109.116 100.196 109.563Z" fill="#76B828"/>
                <path d="M72.1404 116.407L72.1404 122.512L80.3039 119.779L80.3039 113.674L72.1404 116.407Z" fill="#004F90"/>
                <path d="M72.1404 107.074L72.1404 113.179L80.3039 110.445L80.3039 104.34L72.1404 107.074Z" fill="#3372A6"/>
                <path d="M72.1404 97.7336L72.1404 103.838L80.3039 101.105L80.3039 95L72.1404 97.7336Z" fill="#739EC2"/>
            </g>
            <g clipPath="url(#clip1)">
                <path d="M1.50001 704.766L1.50001 604.649L136.5 559.693L136.5 659.81L1.50001 704.766Z" fill="white" stroke="#005191"/>
                <path d="M1.50002 859.529L1.50003 759.426L136.5 714.457L136.5 814.574L1.50002 859.529Z" fill="white" stroke="#005191"/>
                <path d="M1.50001 1014.31L1.50001 914.19L136.5 869.234L136.5 969.351L1.50001 1014.31Z" fill="white" stroke="#005191"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="301" height="72" fill="white" transform="translate(39 396) rotate(-90)"/>
                </clipPath>
                <clipPath id="clip1">
                    <rect width="456" height="136" fill="white" transform="translate(137 559) rotate(90)"/>
                </clipPath>
            </defs>
        </svg>

    );
};
export default LogoStrip;