import smoothscroll from "smoothscroll-polyfill";
import React,{StrictMode} from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {createStore} from "redux";
import { rootReducer} from "./js/reducers";

import { composeWithDevTools } from "redux-devtools-extension";
import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

const store = createStore(
    rootReducer,
    composeWithDevTools()
);

root.render(
    <StrictMode>
        <Provider store={store}>
            <App tab="home" />
        </Provider>
    </StrictMode>
);

smoothscroll.polyfill();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
