import React from 'react';
import Triple from "../svg/Triple";

const Footer = props => {
    const {
        title,
        methods,
        address,
        cover,
        note,
        noteBg,
        noteTxt,
        docs,
        pageType
    } = props;
    return (
        <article
            id="contact"
            className="boxed footer" >
            <div className="row">
                <div className="col col-xs-12 col-sm-1"/>
                <div className="col col-xs-12 col-sm-11">
                    <div className="row center-xs start-sm title">
                        <div className="col col-xs-12 col-sm-3">
                            <h2
                                className="section-title section-title__xl"
                                dangerouslySetInnerHTML={{__html : title}}
                            />
                        </div>
                    </div>
                    <div className="row center-xs start-sm contact-data">
                        <div className="col col-xs-12 col-sm-3"/>
                        <div className="col col-xs-12 col-sm-3">
                            {
                                methods &&
                                <p className="methods">                                    {

                                        methods.map((item,index)=> {
                                                const setHref = () => {
                                                    switch(item.metoda.value){
                                                        case "email":
                                                            return `mailto:${item.etykieta}`;
                                                        case "tel":
                                                            return `tel:${item.etykieta && item.etykieta.replace(/[() ]/g,'')}`;
                                                        default:
                                                            return item.etykieta
                                                    }
                                                };
                                                return(
                                                    <a
                                                        href={setHref()}
                                                        title={ item.metoda.label }
                                                        key={index}
                                                    >
                                                        {item.etykieta}
                                                    </a>
                                                )
                                            }

                                        )
                                    }
                                </p>
                            }

                        </div>
                        <div className="col col-xs-12 col-sm-5">
                            <p dangerouslySetInnerHTML={{__html:address}}/>
                        </div>
                    </div>
                    <div className="row triple">
                        <div className="col col-xs-5">
                            <Triple mainColor="#76B828"/>
                        </div>
                    </div>

                </div>
            </div>
            {
                pageType !== "post" &&
                <div className="row cover" >
                    <div className="col col-xs-12">
                        <img src={cover} alt={title} width={1440} height={550} />
                    </div>
                </div>
            }

            <div
                className="row legal"
                style={{
                    backgroundColor:noteBg,
                    color:noteTxt
                }}
            >
                <div className="col col-xs-12 note">
                    {note}
                </div>
                <div className="col col-xs-12 docs">
                    <ul>
                        {
                            docs &&
                            docs.map((item,index)=>
                                <li key={index}>
                                    <a href={item.file} title={item.name} target="_blank">
                                        {item.name}
                                    </a>
                                </li>
                            )
                        }
                    </ul>

                </div>
            </div>

        </article>
    );
};

export default Footer;