import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleHomeMenu } from "../../../actions/pageActions";

const MenuTrigger = ({expanded,toggleMenu}) => {
    useEffect(()=>{ toggleMenu(expanded) });
    return (
        <div/>
    );
};

const mapDispatchToProps = dispatch => ({
    toggleMenu: value => dispatch( toggleHomeMenu(value) )
});
export default connect(null,mapDispatchToProps)(MenuTrigger);