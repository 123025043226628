import React,{useState} from 'react';
import { Navigate } from "react-router";

//tools
import classnames from "classnames";
import MediaQuery from 'react-responsive';
import {InView} from "react-intersection-observer";

//svg
import Eye from "../../../svg/Eye";
import {config} from "../../../../config";

const OurTeam = (
    {
        title,
        text,
        txtColor,
        members,
        locales
    }) => {

    const [memberHovered, setMemberHovered] = useState(null);
    const [memberClicked, setMemberClicked] = useState(null);
    const [redirectUrl,setRedirectUrl] = useState(false);

    const handlePostSelection = url => {
        setRedirectUrl(url);
    };

    return (
        <InView>
            {({ inView, ref }) => (
            <article
                id="our-team"
                className="boxed"
                style={{color:txtColor}}
                ref={ref}
            >
                {
                    redirectUrl &&
                    <Navigate push to={`/team/${redirectUrl}`}/>
                }
                <div className="row center-xs start-sm">
                    <div className="col col-xs-12 col-sm-3"/>
                    <div className="col col-xs-12 col-sm-2">
                        <h2
                            className={classnames(
                                "section-title section-title__md",{
                                    "animate":inView
                                }
                            )}>
                            {title}
                        </h2>
                    </div>
                    <div className="col col-xs-12 col-sm-7">
                        <p dangerouslySetInnerHTML={{__html:text}}/>
                    </div>
                </div>
                <div className="row  center-xs members">
                            {
                                members &&
                                members.map((item,index)=>{

                                        return(
                                            <div
                                                className={classnames(
                                                    "col col-xs-6 col-sm-5th",
                                                    "member",{
                                                        "hovered":memberHovered===index,
                                                        "clicked":memberClicked===index
                                                    }
                                                )}
                                                key={index}
                                                onMouseOver={()=>setMemberHovered(index)}
                                                onMouseOut={()=>setMemberHovered(null)}
                                            >
                                                <figure onClick={ ()=>setMemberClicked(index) }>

                                                    <img src={item.ot_member_img} alt={`${locales.portret}: ${item.ot_member_name}`} />

                                                    <h3 style={{color: (memberClicked || memberClicked === 0) ? "transparent" : "inherit"}}>
                                                        {item.ot_member_name}
                                                    </h3>
                                                </figure>

                                                {
                                                    index === memberClicked &&
                                                    <figcaption
                                                        className="member__description"
                                                        style={{
                                                            left:`${(index)*20}%`,
                                                            top: index > 4 ? "50%" : "0",
                                                            animation: "stretch .4s 0s 1 forwards"
                                                        }}
                                                        key={index}>
                                                        <nav style={{animation: "fadein .4s 1 forwards"}}>
                                                            <span onClick={ ()=>setMemberClicked(null) }>x</span>
                                                        </nav>
                                                        <figure style={{
                                                            animation: "fadein .5s .4s 1 forwards"
                                                        }}>
                                                            <img src={item.ot_member_img} alt={`Portrait: ${item.ot_member_name}`} />
                                                        </figure>
                                                        <figcaption style={{
                                                            animation: "fadein 1.2s .4s 1 forwards"
                                                        }}>

                                                            <h3>{item.ot_member_name}</h3>
                                                            <p className="member__description__title">{item.ot_member_title}</p>
                                                            <p
                                                                className={classnames(
                                                                    "member__description__text-short",{
                                                                        "member__description__text-short__only" : !item.ot_member_bio_full
                                                                    }
                                                                )}
                                                                dangerouslySetInnerHTML={{__html:item.ot_member_bio}}
                                                            />
                                                            {
                                                                item.ot_member_bio_full &&
                                                                <span
                                                                    className="member__description__more"
                                                                    style={{borderColor: txtColor,color: txtColor}}
                                                                    onClick={ () => handlePostSelection(item.url) }>
                                                                {locales.more}
                                                            </span>
                                                            }

                                                        </figcaption>

                                                    </figcaption>
                                                }
                                                <MediaQuery minWidth={769}>
                                                    {
                                                        index === memberHovered &&
                                                        index !== memberClicked &&

                                                        <figcaption
                                                            className="member__description on-hover"
                                                            style={{
                                                                left:index > 4 ? `${10+(index-4)*20}%` : `${(index)*20}%`,
                                                                top: index > 4 ? "50%" : "0",
                                                                pointerEvents:"none"
                                                            }}
                                                            key={index}>
                                                            <i><Eye mainColor={txtColor}/></i>

                                                            <h3>
                                                                {item.ot_member_name}
                                                            </h3>
                                                        </figcaption>

                                                    }
                                                </MediaQuery>
                                            </div>
                                        )
                                    }
                                )
                            }

                        </div>
            </article>
            )}
        </InView>
    );
};

export default OurTeam;