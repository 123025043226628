import React from 'react'
import { Route, Routes } from 'react-router';
import Home from '../components/Views/Home';
import NewsPost from "../components/Views/NewsPost";
import BioPost from "../components/Views/BioPost";

import { paths } from '../config/paths';

const routes = (
    <Routes>
        <Route index path={paths.home} element={<Home/>} />
        <Route path={paths.news} element={<NewsPost/>} />
        <Route path={paths.team} element={<BioPost/>} />
    </Routes>
);

export default routes;