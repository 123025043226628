import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import Footer from "../../Footer";

import AboutUs from "./Sections/AboutUs";
import OurValues from "./Sections/OurValues";
import OurTeam from "./Sections/OurTeam";
import News from "./Sections/News";
import Sprawozdania from "./Sections/Sprawozdania";
import WhatWeDo from "./Sections/WhatWeDo";
import OurPartners from "./Sections/OurPartners";

//config
import { config } from "../../../config";

class Home extends Component {
    render() {
        const {
            pageData,
            pageOptions,
            pageOptionsNews,
            pageOptionsMembers,
            pageOptionsDocs
        } = this.props;
        return (
            <Page pageIndex={config.pageIndex.home}>
                <main className="page home">

                    <AboutUs
                        title={pageData.aa_title}
                        text={pageData.aa_text}
                        txtColor={pageData.aa_text_color}
                        slides={pageData.aa_slider} />

                    <div
                        className="bg-provider boxed"

                    >
                        <div
                            className="row"
                            style={
                                pageOptions.display_gradient ?
                                    { background: `linear-gradient(180deg, ${pageOptions.gradient_color_1} 0%, ${pageOptions.gradient_color_2} 100%), ${pageOptions.color_blue_dark}` } :
                                    { backgroundColor: pageOptions.color_blue_dark}
                            }
                        >
                            <div className="col col-xs-12 col-sm-1"/>
                            <div className="col col-xs-12 col-sm-10" >
                                <OurValues
                                    title={pageData.ov_title}
                                    text={pageData.ov_text}
                                    txtColor={pageData.ov_text_color}
                                />
                                <OurTeam
                                    title={pageData.ot_title}
                                    text={pageData.ot_text}
                                    txtColor={pageData.ot_text_color}
                                    members={pageOptionsMembers.members_list}
                                    locales={{
                                        portrait: pageOptions.loc_portret,
                                        more: pageOptions.loc_wiecej
                                    } }
                                />
                            </div>
                        </div>

                    </div>

                    <WhatWeDo
                        title={pageData.wwd_title}
                        offer={pageData.oferta}
                        txtColor={pageData.wwd_text_color}
                        bgImage={pageData.wwd_bg}
                        locales={{more: pageOptions.loc_wiecej} }
                    />

                    <OurPartners
                        title={pageData.op_title}
                        txtColor={pageData.ot_text_color}
                        bgColor={pageOptions.color_blue_dark}
                        partners={pageData.op_list}
                    />

                    <News
                        title={pageData.news_title}
                        txtColor={pageData.news_text_color}
                        locales={{
                            download: pageOptions.loc_pobierz,
                            more: pageOptions.loc_wiecej
                        } }
                        bg={
                            pageOptions.display_gradient ?
                                `linear-gradient(180deg, ${pageOptions.gradient_color_1} 0%, ${pageOptions.gradient_color_2} 100%), ${pageOptions.color_blue_dark}`
                                :
                                pageOptions.color_blue_dark
                        }
                        news={pageOptionsNews.news_list}
                        newsDefaultCover={pageData.news_img_default}
                    />

                    <Sprawozdania
                        title={pageData.docs_title}
                        txtColor={pageData.docs_text_color}
                        bg={
                            pageOptions.display_gradient ?
                                `linear-gradient(180deg, ${pageOptions.gradient_color_1} 0%, ${pageOptions.gradient_color_2} 100%), ${pageOptions.color_blue_dark}`
                                :
                                pageOptions.color_blue_dark
                        }
                        docs={pageOptionsDocs}
                        locales={{
                            download: pageOptions.loc_pobierz,
                            documentsList: pageOptions.loc_lista_dokumentow
                        } }
                    />

                </main>
                <Footer
                    title={pageOptions.kontakt_tytul}
                    methods={pageOptions.metody_kontaktu}
                    address={pageOptions.adres}
                    cover={pageOptions.okladka}
                    note={pageOptions.notka_prawna}
                    noteBg={pageOptions.color_blue_dark}
                    noteTxt="#FFFFFF"
                    docs={pageOptions.dokumenty}
                />
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    pageOptionsNews: state.page.pageOptionsNews,
    pageOptionsMembers: state.page.pageOptionsMembers,
    pageOptionsDocs: state.page.pageOptionsDocs
});


export default connect(mapStateToProps,null)(Home);

