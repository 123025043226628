import React from 'react';

//routing
import { BrowserRouter } from "react-router-dom";
import routes from './js/routes';

//config
import {config} from "./js/config";


function App() {
    return (
        <div className="App">
            <BrowserRouter basename={config.domainRelPath}>
                {routes}
            </BrowserRouter>
        </div>
    );
}

export default App;

