import React from 'react';

const Eye = ({mainColor}) => {
    return (
        <svg width="36" height="34" viewBox="0 0 36 34"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={mainColor}
                d="M18 6.375C10.1497 6.375 3.3435 10.693 0 17C3.3435 23.307 10.1497 27.625 18 27.625C25.8503 27.625 32.6565 23.307 36 17C32.6565 10.693 25.8503 6.375 18 6.375ZM26.8762 12.0105C28.9912 13.2855 30.7845 14.9919 32.13 17C30.7845 19.0102 28.9912 20.7166 26.8762 21.9895C24.219 23.5896 21.15 24.4375 18.0023 24.4375C14.8545 24.4375 11.7855 23.5917 9.126 21.9895C7.011 20.7145 5.21775 19.0081 3.87225 17C5.21775 14.9898 7.011 13.2834 9.126 12.0105C9.26325 11.9276 9.40275 11.8469 9.54225 11.7683C9.19125 12.6756 9 13.6553 9 14.6774C9 19.3715 13.0297 23.1774 18 23.1774C22.9703 23.1774 27 19.3715 27 14.6774C27 13.6553 26.8087 12.6756 26.4577 11.7683C26.5972 11.8469 26.7368 11.9276 26.874 12.0105H26.8762ZM18 13.8125C18 15.572 16.488 17 14.625 17C12.762 17 11.25 15.572 11.25 13.8125C11.25 12.053 12.762 10.625 14.625 10.625C16.488 10.625 18 12.053 18 13.8125Z"/>
        </svg>


    );
};

export default Eye;