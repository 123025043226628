import React,{Fragment} from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

//components
import Post from "../../Post";
import Footer from "../../Footer";

//tools
import classnames from "classnames";

//svg
import Download from "../../svg/Download";
import MediaQuery from "react-responsive";



const NewsPost = (
    {
        pageOptionsNews,
        url,
        pageOptions
    }) => {
        const { newsId } = useParams();
        const postId = url ? url : newsId;

        return (
            <Post postId={postId} type="news">
                <main className="page home">
                    <article id="post" className="boxed">
                        <div
                            className="row"
                            style={
                                !pageOptionsNews.post_display_gradient ?
                                    {
                                        color: pageOptionsNews.post_color_text,
                                        backgroundColor:pageOptionsNews.post_color_bg
                                    } :
                                    {
                                        color: pageOptionsNews.post_color_text,
                                        background:`linear-gradient(180deg, ${pageOptions.gradient_color_1} 0%, ${pageOptions.gradient_color_2} 100%), ${pageOptionsNews.post_color_bg}`
                                    }

                            }
                        >
                            <div className="col col-xs-12 col-sm-1"/>
                            <div className="col col-xs-12 col-sm-11">
                                <div className="row" >
                                    <div className="col col-xs-11 line"/>

                                    <div className="col col-xs-12 col-sm-3 title  center-xs start-sm">
                                        <h2 className="section-title section-title__xl">
                                            <span>News</span>
                                        </h2>
                                    </div>
                                    <div className="col col-xs-12 col-sm-8 article_text">
                                        {
                                            pageOptionsNews.news_list &&
                                            pageOptionsNews.news_list
                                                .filter( item => item.url === postId )
                                                .map( (item,index) => {
                                                return(
                                                    <Fragment key={index}>
                                                        <p className="article-text__date">
                                                            {item.date}
                                                        </p>
                                                        <h3 className="article-text__title">{item.title}</h3>
                                                        <div className={classnames(
                                                            "article-text__content",{
                                                                "no-img":!item.img
                                                            }
                                                        )}>
                                                            <span dangerouslySetInnerHTML={{__html:item.text}} />
                                                            {
                                                                item.file &&
                                                                <div className="article-text__file">
                                                                    <span>{pageOptions.loc_pobierz}</span>
                                                                    <a
                                                                        href={item.file}
                                                                        target="_blank"
                                                                        title={`${pageOptions.loc_pobierz}: ${item.title}`}
                                                                    >
                                                                        <Download mainColor={pageOptionsNews.post_color_text}/>
                                                                    </a>

                                                                </div>
                                                            }
                                                        </div>


                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>



                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-xs-12 col-sm-1"/>
                            <div className="col col-xs-12 col-sm-11">
                                <div className="row lifted ">
                                    <div className="col col-xs-12 col-sm-1" />
                                    <div className="col col-xs-12 col-sm-11">
                                        {
                                            pageOptionsNews.news_list &&
                                            pageOptionsNews.news_list
                                                .filter( item => item.url === postId )
                                                .map( (item,index)=>{
                                                    return(
                                                        <div
                                                            key={index}
                                                            className="row center-xs start-sm bottom-xs article article__lifted"
                                                        >
                                                            <div className="col col-xs-12 col-sm-5 triple">
                                                                <div className="row">
                                                                    <div className="col col-xs-5 col-sm-10"/>
                                                                </div>

                                                            </div>
                                                            <MediaQuery minWidth={769}>
                                                                {
                                                                    item.img &&
                                                                    <div
                                                                        className="col col-xs-10 col-sm-6 article-cover"
                                                                        style={{
                                                                            backgroundColor:item.img_bg_color,
                                                                            color: pageOptionsNews.post_color_text
                                                                        }} >

                                                                        <figure>
                                                                            <img
                                                                                src={item.img ? item.img : pageOptionsNews.newsDefaultCover }
                                                                                alt={item.img_description}
                                                                                width={558}
                                                                                height={371} />
                                                                        </figure>

                                                                        {
                                                                            item.img_description &&
                                                                            <figcaption>
                                                                                {item.img_description}
                                                                            </figcaption>
                                                                        }

                                                                    </div>
                                                                }

                                                            </MediaQuery>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <Footer
                        title={pageOptions.kontakt_tytul}
                        methods={pageOptions.metody_kontaktu}
                        address={pageOptions.adres}
                        cover={pageOptions.okladka}
                        note={pageOptions.notka_prawna}
                        noteBg={pageOptions.color_blue_dark}
                        noteTxt="#FFFFFF"
                        docs={pageOptions.dokumenty}
                        pageType="post"
                    />

                </main>
            </Post>
        );

}

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions,
    pageOptionsNews: state.page.pageOptionsNews
});


export default connect(mapStateToProps,null)(NewsPost);