import React,{Fragment} from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

//components
import Post from "../../Post";
import Footer from "../../Footer";



const BioPost = (
    {
        pageOptionsMembers,
        url,
        pageOptions
    }) => {

        const { teamId } = useParams();
        const postId = url ? url : teamId;

        return (
            <Post postId={postId} type="team">
                <main className="page home">
                    <article id="post" className="boxed post-bio">
                        <div
                            className="row"
                            style={
                                !pageOptionsMembers.post_display_gradient ?
                                    {
                                        color: pageOptionsMembers.post_color_text,
                                        backgroundColor:pageOptionsMembers.post_color_bg
                                    } :
                                    {
                                        color: pageOptionsMembers.post_color_text,
                                        background:`linear-gradient(180deg, ${pageOptions.gradient_color_1} 0%, ${pageOptions.gradient_color_2} 100%), ${pageOptionsMembers.post_color_bg}`
                                    }

                            }
                        >
                            <div className="col col-xs-12 col-sm-1"/>
                            <div className="col col-xs-12 col-sm-11">
                                <div className="row" >
                                    <div className="col col-xs-11 line"/>

                                    <div className="col col-xs-12 col-sm-3 title  center-xs start-sm">
                                        {
                                            pageOptionsMembers.members_list &&
                                            pageOptionsMembers.members_list
                                                .filter( item => item.url === postId )
                                                .map( (item,index) =>
                                                    <figure key={index}>
                                                        <img
                                                            src={item.ot_member_img}
                                                            width={400}
                                                            height={542}
                                                        />
                                                    </figure>

                                                )
                                        }
                                    </div>
                                    <div className="col col-xs-12 col-sm-8 article_text">
                                        {
                                            pageOptionsMembers.members_list &&
                                            pageOptionsMembers.members_list
                                                .filter( item => item.url === postId )
                                                .map( (item,index) => {
                                                return(
                                                    <Fragment key={index}>
                                                        <h2 className="article-text__name">{item.ot_member_name}</h2>
                                                        <p className="article-text__title">
                                                            {item.ot_member_title}
                                                        </p>
                                                        <div className="article-text__content">
                                                            <span dangerouslySetInnerHTML={{__html:item.ot_member_bio_full}} />
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-xs-12 col-sm-1"/>
                            <div className="col col-xs-12 col-sm-11">
                                <div className="row lifted ">
                                    <div className="col col-xs-12 col-sm-1" />
                                    <div className="col col-xs-12 col-sm-11">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <Footer
                        title={pageOptions.kontakt_tytul}
                        methods={pageOptions.metody_kontaktu}
                        address={pageOptions.adres}
                        cover={pageOptions.okladka}
                        note={pageOptions.notka_prawna}
                        noteBg={pageOptions.color_blue_dark}
                        noteTxt="#FFFFFF"
                        docs={pageOptions.dokumenty}
                        pageType="post"
                    />

                </main>
            </Post>
        );

}

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions,
    pageOptionsMembers: state.page.pageOptionsMembers
});


export default connect(mapStateToProps,null)(BioPost);