import React, { useState, Fragment, useEffect } from "react";

//tools
import classnames from "classnames";
import { InView } from "react-intersection-observer";
import MediaQuery from "react-responsive";

//svg
import Download from "../../../../svg/Download";
import Triple from "../../../../svg/Triple";

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

const Sprawozdania = ({ title, txtColor, bg, docs, locales }) => {
  const [distinctYears, setDistinctYears] = useState([]);
  const [docYear, setDocYear] = useState(null);

  useEffect(() => {
    const years = docs && docs.map((doc) => doc.rok);
    setDistinctYears(years && years.filter(distinct));
  }, [distinct, docs, setDistinctYears]);

  useEffect(() => {
    if (distinctYears) {
      setDocYear(distinctYears[0]);
    }
  }, [distinctYears, setDocYear]);

  return (
    <InView>
      {({ inView, ref }) => (
        <Fragment>
          {docs && docs.length > 0 && (
            <article id="investor-relations" className="boxed" ref={ref}>
              <div
                className="row"
                style={{
                  color: txtColor,
                  background: bg,
                }}
              >
                <div className="col col-xs-12 col-sm-1" />
                <div
                  className="col col-xs-12 col-sm-11 box"
                  style={{ color: txtColor }}
                >
                  <MediaQuery minWidth={769}>
                    <div className="row">
                      <div
                        className={classnames("col col-xs-11 line", {
                          stretch: inView,
                        })}
                        style={{
                          borderTopColor: txtColor,
                        }}
                      />
                    </div>
                  </MediaQuery>
                  <div className="row center-xs start-sm header">
                    <div className="col col-xs-12 col-sm-3 title">
                      <h2
                        className={classnames(
                          "section-title section-title__xl",
                          {
                            animate: inView,
                          }
                        )}
                      >
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                      </h2>
                      <select onChange={(e) => setDocYear(e.target.value)}>
                        {distinctYears &&
                          distinctYears.map((year, index) => {
                            return (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {docs &&
                    docs
                      .filter((item) => item.rok === docYear)
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="row center-xs start-sm article"
                          >
                            <div className="col col-xs-12 col-sm-6 article-text">
                              <span className="article-text__date">
                                {locales.documentsList}
                              </span>
                              <h3
                                style={{
                                  borderBottomColor: txtColor,
                                }}
                              >
                                {item.rok}
                              </h3>
                              {item.lista_dokumentow && (
                                <ul className="article-text__list">
                                  {item.lista_dokumentow.map((doc, index) => (
                                    <li key={index}>
                                      <MediaQuery minWidth={769}>
                                        {doc.plik && (
                                          <span className="article-text__list__file">
                                            <a
                                              href={doc.plik}
                                              target="_blank"
                                              title={`${locales.download}: ${doc.nazwa}`}
                                            >
                                              <Download mainColor={txtColor} />
                                            </a>
                                          </span>
                                        )}
                                        {doc.nazwa}
                                      </MediaQuery>
                                      <MediaQuery maxWidth={768}>
                                        {doc.plik && (
                                          <span className="article-text__list__file">
                                            <a
                                              href={doc.plik}
                                              target="_blank"
                                              title={`${locales.download}: ${doc.nazwa}`}
                                            >
                                              {doc.nazwa} &#187;
                                            </a>
                                          </span>
                                        )}
                                      </MediaQuery>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                            <div className="col col-xs-12 col-sm-6" />
                          </div>
                        );
                      })}
                </div>
              </div>
            </article>
          )}
        </Fragment>
      )}
    </InView>
  );
};

export default Sprawozdania;
