import React from 'react';

//tools
import classnames from "classnames";
import {InView} from "react-intersection-observer";

//components
import SliderSection from "../../../SliderSection";

//svg
import Triple from "../../../svg/Triple";

const AboutUs = (
    {
        title,
        text,
        slides,
        txtColor
    }) => {
    return (
        <InView>
            {({ inView, ref }) => (
            <article
                id="about-us"
                className="boxed"
                style={{color:txtColor}}
                ref={ref}
            >
                <div
                    className={classnames(
                        "row slider",{
                            "animate":inView
                        }
                    )} >
                    <div className="col col-xs-12">
                        <SliderSection slides={slides}/>
                    </div>
                    <div className="row info">
                        <div className="col col-xs-12">
                            <div className="row center-xs end-sm">
                                <div className="col col-xs-12 col-sm-6">
                                    <h2>{title}</h2>
                                </div>
                                <div className="col col-xs-12 col-sm-6"/>
                            </div>
                            <div className="row">
                                <div className="col col-xs-12 col-sm-4"/>
                                <div
                                    className={classnames(
                                        "col col-xs-12 col-sm-7 line",{
                                            "stretch":inView
                                        }
                                    )}
                                    style={{
                                        borderTopColor:txtColor
                                    }}
                                />
                            </div>
                            <div className="row center-xs start-sm">
                                <div className="col col-xs-12 col-sm-6"/>
                                <div className="col col-xs-12 col-sm-5">
                                    <p dangerouslySetInnerHTML={{__html:text}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row triple">
                    <div className="col col-xs-12 col-sm-1"/>
                    <div className="col col-xs-5 col-sm-5">
                        <Triple mainColor="#76B828"/>
                    </div>
                </div>
            </article>

            )}
        </InView>
    );
};

export default AboutUs;