import React from 'react';

const Triple = ({mainColor}) => {
    return (
        <svg width="461" height="92" viewBox="0 0 461 92" 
             fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M389.338 0H323L352.696 92H419.034L389.338 0Z" fill={mainColor}/>
            <path d="M430.338 0H364L393.705 92H460.034L430.338 0Z" fill={mainColor}/>
            <path d="M228.338 0H162L191.696 92H258.034L228.338 0Z" fill={mainColor}/>
            <path d="M269.338 0H203L232.705 92H299.034L269.338 0Z" fill={mainColor}/>
            <path d="M66.3378 0H0L29.6957 92H96.0335L66.3378 0Z" fill={mainColor}/>
            <path d="M107.338 0H41L70.7046 92H137.034L107.338 0Z" fill={mainColor}/>
        </svg>
    );
};

export default Triple;