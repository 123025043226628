import React from 'react';

const Download = ({mainColor}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
            <path
                fill={mainColor}
                d="M13.5 6L10.5 3H0V22.5H24V6H13.5ZM12 20.25L6.75 15H10.5V9H13.5V15H17.25L12 20.25Z"/>
        </svg>

    );
};

export default Download;