import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

//actions
import { toggleHomeMenu } from "../../../actions/pageActions";
import { setHoveredMenuItemIndex } from "../../../actions/pageActions";

//tools
import classnames from "classnames";
import MediaQuery from "react-responsive";

//svg components
import MenuClose from "../../svg/MenuClose";
import MenuOpen from "../../svg/MenuOpen";

//config
import { config } from "../../../config";

//components
import MenuLang from "../MenuLang";

class MenuTop extends Component {
  constructor(props) {
    super(props);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMenuHash = this.handleMenuHash.bind(this);
    this.handleWindowScroll = this.handleWindowScroll.bind(this);
  }

  componentDidMount() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    !isMobile
      ? window.addEventListener("scroll", this.handleWindowScroll)
      : this.props.toggleMenu(false);
  }

  componentWillUnmount() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    !isMobile
      ? window.removeEventListener("scroll", this.handleWindowScroll)
      : this.props.toggleMenu(false);
  }

  handleMouseEnter(value) {
    this.props.setHoveredMenuItem(value);
  }

  handleMouseLeave() {
    this.props.setHoveredMenuItem(null);
  }

  handleWindowScroll = () => {
    this.props.toggleMenu(window.scrollY <= 0);
  };

  handleMenuHash = (event, id, post) => {
    event.preventDefault();
    if (!post) {
      const el = document.getElementById(id.replace("#", ""));
      const posY = el.offsetTop;
      const posYoffset = window.innerWidth < 769 ? 134 : 0;
      window.scroll({
        top: posY + posYoffset,
        left: 0,
        behavior: "smooth",
      });
      this.props.toggleMenu(false);
    } else {
      window.open(config.domain + config.domainRelPathExt + id, "_self");
    }
  };

  render() {
    const { pageOptions, menuHomeExpanded, post } = this.props;

    const homeUrl = config.domain + config.domainRelPath;

    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          {menuHomeExpanded ? (
            <div
              className="mobile mobile-menu-btn close"
              onClick={() => this.props.toggleMenu(false)}
            >
              <MenuClose mainColor={"#004F90"} />
            </div>
          ) : (
            <div
              className="mobile mobile-menu-btn open"
              onClick={() => this.props.toggleMenu(true)}
            >
              <MenuOpen mainColor={"#004F90"} />
            </div>
          )}
        </MediaQuery>

        <div
          className={classnames("menus-container row center-xs start-sm", {
            expanded: menuHomeExpanded,
          })}
        >
          <div className="col col-xs-12 col-md-2" />
          <div className="col col-xs-12 col-md-10">
            <nav className="menu-top">
              <ul className="row" style={{ width: "101%" }}>
                {this.props.menu
                  .filter((item) => {
                    return item.post_parent == 0 && item.menu_item_parent == 0;
                  })
                  .map((item) => (
                    <li
                      key={item.url}
                      data-id={item.object_id}
                      className={classnames("col col-xs-12 col-sm-2", {
                        active: item.ID == this.props.focusedMenuItem,
                      })}
                      onMouseEnter={() => this.handleMouseEnter(item.ID)}
                    >
                      <a
                        href={post ? homeUrl : item.url}
                        target={"_top"}
                        onClick={(e) => this.handleMenuHash(e, item.url, post)}
                      >
                        <span className="span-link">{item.title}</span>
                      </a>
                      <div>
                        <ul onMouseLeave={this.handleMouseLeave}>
                          {this.props.menu
                            .filter((childItem) => {
                              return (
                                childItem.menu_item_parent == item.object_id
                              );
                            })
                            .map((childItem) => (
                              <li
                                key={childItem.ID}
                                data-parent-id={item.object_id}
                                data-child-id={childItem.post_parent}
                              >
                                <a
                                  href={post ? homeUrl : childItem.url}
                                  target={"_top"}
                                  onClick={(e) =>
                                    this.handleMenuHash(e, childItem.url, post)
                                  }
                                >
                                  <span className="span-link">
                                    {childItem.title}
                                  </span>
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
          <MenuLang languages={pageOptions.jezyki} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  focusedMenuItem: state.page.focusedMenuItem,
  pageOptions: state.page.pageOptions,
  menuHomeExpanded: state.page.menuHomeExpanded,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: (value) => dispatch(toggleHomeMenu(value)),
  setHoveredMenuItem: (value) => dispatch(setHoveredMenuItemIndex(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuTop);
