import React,{useState} from 'react';
import classnames from"classnames";

const MenuLang = ({languages}) => {
    const [active, setActive] = useState(null);
    return (
        <nav className="menu-lang">
            <ul>
                {
                    languages &&
                    languages.map( (item,index) => {
                        return(
                            <li
                                key={index}
                                className={ classnames({
                                    "default" : item.default,
                                    "active" : index === active
                                })}
                                onMouseOver={ () => setActive(index) }
                            >
                                <a
                                    href={item.url}
                                    title={item.name}>
                                    {item.id}
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    );
};

export default MenuLang;