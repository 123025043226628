import React from 'react';

const MenuOpen= ({mainColor}) => {
    return (
        <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35.001px" height="29px" viewBox="144.159 502.25 35.001 29" >
            <line fill="none" stroke={mainColor} strokeWidth="2" strokeMiterlimit="10" x1="144.159" y1="503.316" x2="179.16" y2="503.316"/>
            <line fill="none" stroke={mainColor} strokeWidth="2" strokeMiterlimit="10" x1="144.159" y1="530.377" x2="179.16" y2="530.377"/>
            <line fill="none" stroke={mainColor} strokeWidth="2" strokeMiterlimit="10" x1="144.159" y1="516.766" x2="179.16" y2="516.766"/>
        </svg>
    );
};

export default MenuOpen;