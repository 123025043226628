import React from 'react';

//tools
import Slider from "react-slick";

const SliderSection = ({slides}) => {
    const sliderSettings = {
        autoplay:true,
        autoplaySpeed: 5000,
        cssEase:'ease-in-out',
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide:0
    };
    return (
        <div className="slider-container">
            <Slider {...sliderSettings}>
                {
                    slides &&
                    slides
                    .map((item,index) =>
                        <div key={index}>
                            <img
                                src={item.aa_slide_img}
                                alt={item.aa_slide_title}
                                className="banner"/>
                        </div>
                    )
                }
            </Slider>
        </div>
    );
};

export default SliderSection;