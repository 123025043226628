import React,{useState,Fragment,useEffect} from 'react';
import { Navigate } from "react-router";

//tools
import classnames from "classnames";
import {InView} from "react-intersection-observer";
import MediaQuery from 'react-responsive';

//svg
import Download from "../../../../svg/Download";
import Triple from "../../../../svg/Triple";

const distinct = (value,index,self) => {
    return self.indexOf(value) === index;
};

const News = (
    {
        title,
        news,
        txtColor,
        locales,
        bg,
        newsDefaultCover
    }) => {
    const [selectedNews,setSelectedNews] = useState(0);
    const [redirectUrl,setRedirectUrl] = useState(false);

    const years = news && news.map(item => item.date.split('/')[2] );
    const distinctYears = years && years.filter(distinct);

    const [postsYear,setPostsYear] = useState(null);

    const handlePostSelection = url => {
        setRedirectUrl(url);
    };

    useEffect( () => {
        if (distinctYears) {
            setPostsYear(distinctYears[0])
        }
    },[distinctYears,setPostsYear])




    return (
        <InView>
            {({ inView, ref }) => (
            <Fragment>
                {
                    news &&
                    news.length > 0 &&
                    <article
                        id="news"
                        className="boxed"
                        ref={ref}
                    >
                        {
                            redirectUrl &&
                            <Navigate push to={`/news/${redirectUrl}`}/>
                        }
                        <div
                            className="row"
                            style={{
                                color:txtColor,
                                background: bg
                            }}
                        >
                            <div className="col col-xs-12 col-sm-1" />
                            <div
                                className="col col-xs-12 col-sm-11 box"
                                style={{color:txtColor}}

                            >
                                <MediaQuery minWidth={769}>
                                    <div className="row">
                                        <div
                                            className={classnames(
                                                "col col-xs-11 line",{
                                                    "stretch":inView
                                                }
                                            )}
                                            style={{
                                                borderTopColor: txtColor
                                            }}
                                        />
                                    </div>
                                </MediaQuery>
                                <div className="row center-xs start-sm header">
                                    <div className="col col-xs-12 col-sm-3 title">
                                        <h2
                                            className={classnames(
                                                "section-title section-title__xl",{
                                                    "animate":inView
                                                }
                                            )}>
                                            <span>{title}</span>
                                        </h2>
                                        <select onChange={e=>setPostsYear(e.target.value)}>
                                            {
                                                distinctYears &&
                                                distinctYears.map((item,index) => {
                                                    return(
                                                        <option
                                                            key={index}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                    <div className="col col-xs-12 col-sm-8 news-list">
                                        {
                                            news &&
                                            news
                                                .filter(item=>item.date.includes(postsYear))
                                                .filter(item=>item.featured)
                                                .map((item,index)=>
                                                    <div
                                                        className={classnames(
                                                            "row news-item",{
                                                                "active" : selectedNews === index
                                                            }
                                                        )}
                                                        key={index}
                                                        onClick={()=>setSelectedNews(index)}
                                                        style={{
                                                            borderBottomColor: txtColor
                                                        }}
                                                    >
                                                        <div className="col col-xs-12 col-sm-4 item-date">
                                                            {item.date}
                                                        </div>
                                                        <div
                                                            className={classnames(
                                                                "col col-xs-12 col-sm-7 item-title",{
                                                                    "active" : selectedNews === index
                                                                }
                                                            )}
                                                        >
                                                            {item.title}
                                                        </div>
                                                        {
                                                            item.file &&
                                                            <div className="col col-xs-12 col-sm-1 file">

                                                                <a
                                                                    href={item.file}
                                                                    target="_blank"
                                                                    title={`${locales.download}: ${item.title}`}
                                                                >
                                                                    <Download mainColor={txtColor}/>
                                                                </a>

                                                            </div>
                                                        }
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                                {
                                    news &&
                                    news
                                        .filter(item => item.featured)
                                        .filter((item,index)=>index===selectedNews)
                                        .map( (item,index)=>{
                                            return(
                                                <div key={index} className="row center-xs start-sm article">
                                                    <div className="col col-xs-12 col-sm-6 article-text">
                                                        <span className="article-text__date">{item.date}</span>
                                                        <h3 style={{
                                                            borderBottomColor: txtColor
                                                        }}>{item.title}</h3>
                                                        <div
                                                            className="article-text__content"
                                                            dangerouslySetInnerHTML={{__html:item.text}}
                                                        />

                                                        <span
                                                            className="article-text__more"
                                                            style={{borderColor: txtColor,color: txtColor}}
                                                            onClick={ () => handlePostSelection(item.url) }>
                                                        {locales.more}
                                                    </span>


                                                        {
                                                            item.file &&
                                                            <div className="article-text__file">
                                                                <a
                                                                    href={item.file}
                                                                    target="_blank"
                                                                    title={`${locales.download}: ${item.title}`}
                                                                >
                                                                    <Download mainColor={txtColor}/>
                                                                </a>

                                                            </div>
                                                        }


                                                    </div>
                                                    <div className="col col-xs-12 col-sm-6"/>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>

                        <div className="row lifted">
                            <div className="col col-xs-12 col-sm-1" />
                            <div className="col col-xs-12 col-sm-11">
                                {
                                    news &&
                                    news
                                        .filter(item => item.featured)
                                        .filter((item,index)=>index===selectedNews)
                                        .map( (item,index)=>{
                                            return(
                                                <div key={index} className="row bottom-xs article article__lifted">
                                                    <div className="col col-xs-12 col-sm-6 triple">
                                                        <div className="row">
                                                            <div className="col col-xs-5 col-sm-10">
                                                                <MediaQuery maxWidth={768}>
                                                                    <Triple mainColor="#76B828"/>
                                                                </MediaQuery>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <MediaQuery minWidth={769}>
                                                        {
                                                            item.img &&
                                                            <div
                                                                className="col col-xs-12 col-sm-6 article-cover"
                                                                style={{
                                                                    backgroundColor:item.img_bg_color,
                                                                    color: txtColor
                                                                }} >

                                                                <figure>
                                                                    <img
                                                                        src={item.img ? item.img : newsDefaultCover }
                                                                        alt={item.img_description}
                                                                        width={558}
                                                                        height={371} />
                                                                </figure>
                                                                {
                                                                    item.img_description &&
                                                                    <figcaption>
                                                                        {item.img_description}
                                                                    </figcaption>
                                                                }
                                                            </div>
                                                        }

                                                    </MediaQuery>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </article>
                }

            </Fragment>
            )}
        </InView>
    );
};




export default News;