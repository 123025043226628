import React from 'react';

const Logo = () => {
    return (
        <svg width="302" height="73"
             viewBox="0 0 302 73" fill="none"
             xmlns="http://www.w3.org/2000/svg"
             aria-labelledby="vistula-gp-logo"
        >
            <title id="vistula-gp-logo">Vistula GP Logo</title>
            <g clipPath="url(#clip0)">
                <path d="M290.722 45.0248H280.737L282.596 50.5955H292.757L290.722 45.0248Z" fill="#004F90"/>
                <path d="M264.586 33.6404L270.895 15.4969L275.867 30.4196L285.37 30.4196L274.537 0.851135L266.825 0.851135L248.673 50.5955H258.834L261.853 41.804L273.486 41.804L270.752 33.6404H264.586Z" fill="#004F90"/>
                <path d="M50.5321 50.5955L50.5321 0.91864L60.2726 0.91864L60.2726 50.5887H50.5321V50.5955Z" fill="#004F90"/>
                <path d="M30.4134 32.1009L40.7983 0.91864L30.6372 0.91864L25.7059 16.8339L30.4134 32.1009Z" fill="#004F90"/>
                <path d="M14.9005 14.8284L10.5933 0.91864L0.5 0.91864L16.9694 50.5955L24.2612 50.5955L25.1226 47.9891L14.9005 14.8284Z" fill="#004F90"/>
                <path d="M99.4586 47.1045C96.0467 49.7109 91.6581 51.0141 86.2859 51.0141C78.4853 51.0141 72.4076 48.8264 68.0665 44.4576L74.3747 38.178C77.1762 40.9667 81.1985 42.3644 86.4283 42.3644C92.316 42.3644 95.2599 40.3184 95.2599 36.2265C95.2599 34.4102 94.7444 33.0192 93.7201 32.0401C92.7841 31.2028 91.3121 30.6221 89.3043 30.298L83.2742 29.4607C79.0212 28.8598 75.7992 27.4823 73.6015 25.3418C71.2206 22.9717 70.0268 19.7171 70.0268 15.5712C70.0268 11.0606 71.5665 7.43464 74.6529 4.68644C77.8273 1.89773 82.0803 0.5 87.4051 0.5C94.1339 0.5 99.5061 2.43116 103.522 6.29349L97.3559 12.3638C94.975 10.0883 91.5631 8.94716 87.127 8.94716C84.6511 8.94716 82.7112 9.57512 81.3071 10.8311C80.0454 11.9452 79.4146 13.4104 79.4146 15.2268C79.4146 16.6718 79.903 17.8535 80.8865 18.7853C82.0057 19.8116 83.5523 20.4599 85.5126 20.7367L91.3935 21.5672C95.5991 22.175 98.7735 23.4511 100.924 25.4026C103.495 27.8199 104.777 31.3311 104.777 35.9362C104.783 40.6426 103.006 44.3631 99.4586 47.1045Z" fill="#004F90"/>
                <path d="M137.308 9.56836V50.5887L127.568 50.5887V9.56836L114.531 9.56836V0.91864L150.345 0.91864V9.56836L137.308 9.56836Z" fill="#004F90"/>
                <path d="M196.81 33.5661C196.81 38.7316 195.032 42.9586 191.485 46.2605C188.026 49.4206 183.684 51.0074 178.448 51.0074C173.211 51.0074 168.87 49.4273 165.411 46.2605C161.856 42.9586 160.086 38.7249 160.086 33.5661V0.91864L169.826 0.91864V33.2217C169.826 36.0577 170.6 38.2927 172.139 39.9201C173.679 41.5474 175.782 42.3644 178.448 42.3644C181.114 42.3644 183.216 41.5541 184.756 39.9201C186.343 38.2927 187.137 36.0577 187.137 33.2217V0.91864L196.81 0.91864V33.5661Z" fill="#004F90"/>
                <path d="M206.557 50.5955V0.91864L216.298 0.91864V41.939L238.933 41.939V50.5887L206.557 50.5887V50.5955Z" fill="#004F90"/>
                <path d="M120.581 61.9866C119.353 61.9866 118.411 62.3648 117.766 63.1278C117.115 63.8908 116.789 64.9442 116.789 66.2946C116.789 66.9361 116.864 67.5235 117.02 68.0502C117.176 68.5837 117.407 69.0361 117.712 69.4142C118.017 69.7923 118.397 70.0894 118.851 70.2987C119.306 70.5081 119.835 70.6161 120.446 70.6161C120.825 70.6161 121.151 70.6026 121.429 70.5756C121.707 70.5486 121.924 70.5081 122.087 70.4608V66.1933H124.258V71.9058C123.959 72.0206 123.45 72.1489 122.745 72.2839C122.04 72.419 121.212 72.4865 120.283 72.4865C119.421 72.4865 118.634 72.3514 117.922 72.0746C117.21 71.7978 116.606 71.3994 116.104 70.8727C115.602 70.346 115.216 69.6978 114.944 68.9348C114.666 68.1718 114.531 67.2872 114.531 66.2946C114.531 65.302 114.68 64.4175 114.985 63.6545C115.29 62.8914 115.704 62.2432 116.233 61.7098C116.762 61.1764 117.38 60.778 118.092 60.5011C118.804 60.2243 119.564 60.0892 120.364 60.0892C120.914 60.0892 121.409 60.123 121.836 60.204C122.263 60.2783 122.636 60.3593 122.948 60.4539C123.26 60.5484 123.511 60.6429 123.715 60.7442C123.918 60.8455 124.054 60.9198 124.136 60.967L123.484 62.7361C123.138 62.5336 122.711 62.358 122.202 62.2162C121.68 62.0609 121.144 61.9866 120.581 61.9866Z" fill="#76B828"/>
                <path d="M135.999 60.2378C137.722 60.2378 139.038 60.5552 139.954 61.1831C140.869 61.8111 141.324 62.7699 141.324 64.0664C141.324 65.6802 140.523 66.7673 138.929 67.3412C139.147 67.6046 139.397 67.9219 139.669 68.3001C139.94 68.6782 140.225 69.0833 140.51 69.5222C140.795 69.9611 141.073 70.4136 141.337 70.8795C141.602 71.3454 141.839 71.7978 142.043 72.2434H139.615C139.397 71.8315 139.16 71.4196 138.909 71.001C138.658 70.5824 138.4 70.1772 138.143 69.7856C137.885 69.3939 137.627 69.0226 137.376 68.6714C137.125 68.3203 136.888 68.0097 136.671 67.7396C136.508 67.7531 136.372 67.7599 136.257 67.7599C136.142 67.7599 136.033 67.7599 135.931 67.7599H134.88V72.2502H132.709V60.5281C133.238 60.4134 133.801 60.3391 134.398 60.2986C134.995 60.258 135.531 60.2378 135.999 60.2378ZM136.155 62.1082C135.694 62.1082 135.273 62.1284 134.88 62.1622V66.0043H135.83C136.359 66.0043 136.82 65.9773 137.227 65.9165C137.634 65.8557 137.966 65.7544 138.238 65.6059C138.509 65.4573 138.712 65.2548 138.848 65.0049C138.984 64.7551 139.052 64.431 139.052 64.0461C139.052 63.6815 138.984 63.3709 138.848 63.121C138.712 62.8712 138.509 62.6686 138.251 62.5201C137.993 62.3715 137.688 62.2635 137.329 62.2027C136.969 62.1419 136.582 62.1082 136.155 62.1082Z" fill="#76B828"/>
                <path d="M149.674 72.2434V60.3593H157.339V62.1959H151.844V65.1265H156.735V66.9293H151.844V70.4068H157.753V72.2434H149.674Z" fill="#76B828"/>
                <path d="M165.716 72.2434V60.3593H173.381V62.1959H167.887V65.1265H172.777V66.9293H167.887V70.4068H173.795V72.2434H165.716Z" fill="#76B828"/>
                <path d="M189.796 72.2434C189.402 71.5952 188.968 70.8929 188.487 70.1434C188.005 69.3939 187.503 68.6377 186.988 67.8814C186.472 67.1251 185.943 66.3824 185.414 65.6666C184.878 64.9509 184.369 64.3094 183.888 63.7355V72.2434H181.751V60.3593H183.528C183.99 60.8387 184.485 61.4059 185.007 62.0677C185.536 62.7226 186.065 63.4046 186.601 64.1136C187.137 64.8226 187.652 65.5316 188.154 66.2473C188.656 66.9631 189.097 67.6248 189.491 68.246V60.3593H191.641V72.2434H189.796Z" fill="#76B828"/>
                <path d="M213.591 60.2378C215.266 60.2378 216.555 60.5551 217.457 61.1899C218.36 61.8246 218.807 62.7699 218.807 64.0258C218.807 64.7146 218.685 65.2953 218.434 65.7814C218.183 66.2676 217.83 66.6592 217.369 66.9631C216.901 67.267 216.338 67.483 215.673 67.6248C215.009 67.7599 214.256 67.8274 213.415 67.8274H212.085V72.2367H210.417V60.5281C210.885 60.4134 211.414 60.3391 211.99 60.2986C212.574 60.258 213.103 60.2378 213.591 60.2378ZM213.727 61.6963C213.014 61.6963 212.472 61.7165 212.092 61.7503V66.4162H213.347C213.923 66.4162 214.439 66.3824 214.9 66.3014C215.361 66.2271 215.748 66.0988 216.06 65.9232C216.372 65.7477 216.623 65.5046 216.793 65.194C216.962 64.8834 217.05 64.4918 217.05 64.0123C217.05 63.5532 216.962 63.175 216.786 62.8779C216.609 62.5808 216.365 62.3445 216.06 62.169C215.755 61.9934 215.402 61.8651 215.002 61.7976C214.602 61.7301 214.174 61.6963 213.727 61.6963Z" fill="#76B828"/>
                <path d="M226.35 66.2946C226.35 65.275 226.499 64.377 226.804 63.6004C227.11 62.8239 227.523 62.1757 228.046 61.6558C228.568 61.1358 229.179 60.7442 229.87 60.4809C230.562 60.2175 231.302 60.0892 232.082 60.0892C232.848 60.0892 233.581 60.2175 234.259 60.4809C234.944 60.7442 235.541 61.1358 236.057 61.6558C236.572 62.1757 236.986 62.8239 237.291 63.6004C237.596 64.377 237.746 65.275 237.746 66.2946C237.746 67.3142 237.596 68.2123 237.291 68.9888C236.986 69.7653 236.579 70.4135 236.057 70.9335C235.541 71.4534 234.937 71.845 234.259 72.1084C233.574 72.3717 232.848 72.5 232.082 72.5C231.302 72.5 230.562 72.3717 229.87 72.1084C229.172 71.845 228.568 71.4534 228.046 70.9335C227.523 70.4135 227.11 69.7653 226.804 68.9888C226.506 68.2055 226.35 67.3075 226.35 66.2946ZM228.107 66.2946C228.107 67.0171 228.195 67.6721 228.385 68.2595C228.568 68.847 228.833 69.3467 229.172 69.7586C229.511 70.1705 229.925 70.4878 230.413 70.7106C230.901 70.9335 231.451 71.0483 232.055 71.0483C232.658 71.0483 233.208 70.9335 233.689 70.7106C234.171 70.4878 234.585 70.1705 234.924 69.7586C235.263 69.3467 235.521 68.847 235.711 68.2595C235.894 67.6721 235.989 67.0171 235.989 66.2946C235.989 65.5721 235.894 64.9171 235.711 64.3297C235.528 63.7422 235.263 63.2426 234.924 62.8307C234.585 62.4188 234.178 62.1014 233.689 61.8786C233.208 61.6558 232.658 61.541 232.055 61.541C231.451 61.541 230.895 61.6558 230.413 61.8786C229.925 62.1014 229.511 62.4188 229.172 62.8307C228.833 63.2426 228.575 63.7422 228.385 64.3297C228.202 64.9171 228.107 65.5721 228.107 66.2946Z" fill="#76B828"/>
                <path d="M252.431 64.1811C251.949 65.7139 251.461 67.1319 250.966 68.4351C250.471 69.7383 249.976 71.0077 249.487 72.2434H247.819C247.208 70.4946 246.672 68.6512 246.211 66.7065C245.743 64.7618 245.309 62.6484 244.909 60.3593H246.665C246.828 61.2506 246.991 62.1419 247.167 63.0332C247.344 63.9245 247.52 64.7888 247.703 65.6329C247.886 66.4702 248.069 67.2737 248.253 68.0435C248.436 68.8065 248.626 69.5155 248.822 70.1704C249.284 68.9685 249.772 67.6518 250.288 66.2203C250.803 64.7888 251.285 63.3033 251.732 61.7705H253.177C253.625 63.3033 254.113 64.7888 254.642 66.2203C255.171 67.6518 255.667 68.9753 256.121 70.1704C256.304 69.529 256.487 68.8267 256.67 68.0637C256.854 67.3007 257.037 66.4972 257.213 65.6531C257.389 64.8091 257.566 63.9448 257.742 63.0467C257.912 62.1487 258.081 61.2574 258.244 60.3661H259.947C259.533 62.6551 259.092 64.7686 258.631 66.7133C258.163 68.6579 257.634 70.5013 257.037 72.2502H255.368C254.859 71.0145 254.371 69.7451 253.889 68.4419C253.401 67.1319 252.913 65.7139 252.431 64.1811Z" fill="#76B828"/>
                <path d="M267.849 72.2434V60.3593H275.134V61.7841H269.518V65.3155H274.517V66.7065H269.518V70.8254H275.561V72.2502H267.849V72.2434Z" fill="#76B828"/>
                <path d="M289.467 67.2737C289.65 67.5033 289.881 67.8004 290.165 68.1718C290.444 68.5432 290.735 68.955 291.034 69.4075C291.332 69.8599 291.631 70.3325 291.922 70.8254C292.214 71.3184 292.465 71.791 292.675 72.2502H290.851C290.62 71.818 290.369 71.3791 290.104 70.9267C289.833 70.4811 289.562 70.0557 289.284 69.6505C289.006 69.2454 288.734 68.8673 288.463 68.5094C288.192 68.1515 287.947 67.8477 287.73 67.5843C287.581 67.5978 287.432 67.6046 287.276 67.6046C287.12 67.6046 286.964 67.6046 286.801 67.6046H285.302V72.2502H283.633V60.5281C284.101 60.4134 284.63 60.3391 285.207 60.2986C285.784 60.258 286.319 60.2378 286.801 60.2378C288.476 60.2378 289.758 60.5552 290.633 61.1831C291.509 61.8111 291.949 62.7497 291.949 63.9988C291.949 64.7889 291.739 65.4641 291.319 66.0245C290.905 66.5782 290.288 66.9969 289.467 67.2737ZM286.937 61.6963C286.224 61.6963 285.682 61.7166 285.302 61.7503V66.2271H286.489C287.066 66.2271 287.581 66.2001 288.042 66.1393C288.504 66.0853 288.89 65.9705 289.202 65.8152C289.514 65.6599 289.765 65.4303 289.935 65.14C290.104 64.8496 290.193 64.4647 290.193 63.9853C290.193 63.5397 290.104 63.1683 289.935 62.8712C289.765 62.5741 289.528 62.3378 289.236 62.169C288.944 62.0002 288.599 61.8786 288.205 61.8111C287.812 61.7436 287.384 61.6963 286.937 61.6963Z" fill="#76B828"/>
                <path d="M280.093 33.6404H273.988L276.721 41.804H282.826L280.093 33.6404Z" fill="#004F90"/>
                <path d="M289.426 33.6404H283.321L286.055 41.804H292.16L289.426 33.6404Z" fill="#3372A6"/>
                <path d="M298.766 33.6404H292.662L295.395 41.804H301.5L298.766 33.6404Z" fill="#739EC2"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="301" height="72" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Logo;