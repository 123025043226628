import React, {Component,Fragment} from 'react';
import { connect } from "react-redux";
import axios from "axios";

//actions
import {setHoveredMenuItemIndex, toggleHomeMenu} from "../../actions/pageActions";

// components
import MenuTop from '../Menu/MenuTop';

//tools
import MediaQuery from 'react-responsive';
import MenuTrigger from "../Menu/MenuTrigger";
import classnames from "classnames";
import {InView} from "react-intersection-observer";

//config
import { config } from "../../config";

//svg
import MenuOpen from "../svg/MenuOpen";
import LogoStrip from "../svg/LogoStrip";
import Logo from "../svg/Logo";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu:[]
        };
        this.handleMouseEnterLogo = this.handleMouseEnterLogo.bind(this);
    }

    handleMouseEnterLogo(){
        this.props.setHoveredMenuItem(null)
    }

    componentDidMount() {
        axios
            .get(
                config.domainRelPath+'/wp-json/api/menu/'
            )
            .then(res => {
                this.setState({
                    menu: res.data
                });
            })
            .catch(error => console.log(error));
    }
    render() {
        const {
            post,
            menuHomeExpanded
        } = this.props;
        return (
            <Fragment>
                <MediaQuery minWidth={769}>
                    <InView>
                        {({ inView, ref }) => (
                            <div  ref={ref}
                                  className={classnames({
                                      "triggered":inView
                                  })}>
                                <MenuTrigger expanded={inView} />
                            </div>
                        )}
                    </InView>
                    {
                        !menuHomeExpanded &&
                        <div className="desktop-menu-btn"
                             onClick={()=>this.props.toggleMenu(true)}>
                            <MenuOpen mainColor={"#91c653"}/>
                        </div>
                    }
                </MediaQuery>
                <header className="main-header" >
                    <MediaQuery minWidth={769}>
                        <div className="boxed">
                            <h1 className="logo-strip" aria-label="Vistula Green Power">
                                <a href={config.domain+config.domainRelPath}
                                   title="Link: Home"
                                   target="_top">
                                    <LogoStrip />
                                </a>
                            </h1>
                        </div>
                    </MediaQuery>

                    <div
                        className="menu-container"
                        data-menu-expanded={menuHomeExpanded ? 'expanded' : 'collapsed'} >
                        <div className="boxed">
                            <MediaQuery maxWidth={768}>
                                <h1 className="logo" aria-label="Vistula Green Power">
                                    <a href={config.domain+config.domainRelPath}
                                       title="Link: Home"
                                       target="_top">
                                        <Logo />
                                    </a>
                                </h1>
                            </MediaQuery>
                            <MenuTop role="menu"
                                     post={!!post}
                                     menu={this.state.menu}
                                     expanded={menuHomeExpanded} />
                        </div>
                    </div>

                </header>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    menuHomeExpanded: state.page.menuHomeExpanded
});

const mapDispatchToProps = dispatch => ({
    setHoveredMenuItem: value => dispatch( setHoveredMenuItemIndex (value)),
    toggleMenu: value => dispatch( toggleHomeMenu(value) ),
});


export default connect(mapStateToProps,mapDispatchToProps)(Header);

