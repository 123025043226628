import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";

//actions
import { toggleOverlay } from "../../actions/pageActions";
import {
    setPageOptions,
    setPageOptionsNews,
    setPageOptionsMembers
} from "../../actions/pageActions";

//tools
import MediaQuery from 'react-responsive';

// components
import Header from '../Header';

//config
import { config } from "../../config";

class Post extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: []
        };
        this.handleOverlay = this.handleOverlay.bind(this);
    }
    handleOverlay() {
        this.props.showOverlay(false);
    }


    componentDidMount(props) {
        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-vistula')
            .then( res => { this.props.dispatchPageDataOptions( res.data.acf ) } )
            .catch(error => console.log(error));


        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-vistula-'+this.props.type)
            .then(
                res => {
                    switch(this.props.type) {
                        case "team":
                            this.props.dispatchPageDataMembers( res.data.acf );
                            break;
                        case "news":
                            this.props.dispatchPageDataNews( res.data.acf );
                            break;
                        default:
                            this.props.dispatchPageDataNews( res.data.acf );
                    }

                } )
            .catch(error => console.log(error));

        window.scrollTo(0,0)
    }

    render() {
        return (
            <Fragment>
                <MediaQuery maxWidth={768}>
                    <a className="home" href={config.domain+config.domainRelPath}>HOME</a>
                </MediaQuery>
                <Header post />
                {this.props.children}
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    showOverlay: value => dispatch(toggleOverlay(value)),
    dispatchPageDataOptions: data => dispatch( setPageOptions(data) ),
    dispatchPageDataNews: data => dispatch( setPageOptionsNews(data) ),
    dispatchPageDataMembers: data => dispatch( setPageOptionsMembers(data) )
});

export default connect(null,mapDispatchToProps)(Post);

