export const SET_HOVERED_MENU_ITEM = 'setHoveredMenuItemIndex';
export const SET_PAGE_DATA = 'setPageDataFromAcf';
export const SET_PAGE_OPTIONS = 'setPageOptionsFromAcf';
export const SET_PAGE_OPTIONS_DOCS = 'setPageOptionsFromAcfDocs';
export const SET_PAGE_OPTIONS_NEWS = 'setPageOptionsFromAcfNews';
export const SET_PAGE_OPTIONS_MEMBERS = 'setPageOptionsFromAcfMembers';
export const TOGGLE_HOME_MENU = 'menuHomeToggleState';
export const TOGGLE_OVERLAY = 'routesToggleOverlay';

export const toggleHomeMenu = value => ({
    type: TOGGLE_HOME_MENU,
    value
});

export const toggleOverlay = value => ({
    type: TOGGLE_OVERLAY,
    value
});

export const setPageData = data => ({
    type: SET_PAGE_DATA,
    data
});

export const setHoveredMenuItemIndex = value => ({
    type: SET_HOVERED_MENU_ITEM,
    value
});

export const setPageOptions = data => ({
    type: SET_PAGE_OPTIONS,
    data
});

export const setPageOptionsNews = data => ({
    type: SET_PAGE_OPTIONS_NEWS,
    data
});

export const setPageOptionsDocs = data => ({
    type: SET_PAGE_OPTIONS_DOCS,
    data
});

export const setPageOptionsMembers = data => ({
    type: SET_PAGE_OPTIONS_MEMBERS,
    data
});

