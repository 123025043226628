import React from 'react';

const MenuClose = ({mainColor}) => {
    return (
        <svg version="1.1" width="255.418px" height="257.25px" id="menu_close" xmlns="http://www.w3.org/2000/svg" viewBox="190.417 286.333 255.418 257.25" >
            <line fill="none" stroke={mainColor} strokeWidth="20" strokeMiterlimit="10" x1="191.25" y1="542.88" x2="445.23" y2="287.37"/>
            <line fill="none" stroke={mainColor} strokeWidth="20" strokeMiterlimit="10" x1="445.23" y1="542.88" x2="191.25" y2="287.37"/>
        </svg>
    );
};

export default MenuClose;